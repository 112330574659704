import { Fragment, useEffect, useRef, useState } from "react";
import "./App.css";
import "./components/Icons/fontAwesomeLibrary";
import "bootstrap/dist/css/bootstrap.css";
import Body from "./components/Layout/Body/Body";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Management from "./components/Pages/Management/Management";
import theme from "./components/Theme/Theme";
import Login from "./components/Layout/Login/Login";
import ForgotPassword from "./components/ForgetPassword/ForgetPassword";
import ActivationAccount from "./components/Layout/Login/ActivateAccount";
import { useAuthContext } from "./components/context/auth/authState.js";
import { logoutUser } from "./components/context/auth/authReducer";
import storageService from "./components/utils/localStorageHelpers.js";
import { Navigate } from "react-router-dom";
import ProtectedRoutes from "./components/Layout/Login/ProtectedRoutes";
import Reset from "./components/ForgetPassword/Reset";
import UserManagement from "./components/Pages/User/UserManagement";
import axios from "axios";
import backendUrl from "./components/utils/backendUrl";

import Profile from "./components/Pages/Profile/Profile";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { savePrivilages } from "./redux/auth";
import { saveSite } from "./redux/site";
import CryptoJS from "crypto-js";
import NotFound from "./components/Pages/NotFound/NotFound";
import SitesManagement from "./components/Pages/SiteManagment/SitesManagement";
import ViewSiteDetails from "./components/Pages/SiteManagment/ViewSiteDetails";
import AddSite from "./components/Pages/SiteManagment/AddSite";
import EditSite from "./components/Pages/SiteManagment/EditSite";
import OrganisationManagement from "./components/Pages/OrganisationManagement/OrganisationManagement";
import AddOrganisation from "./components/Pages/OrganisationManagement/AddOrganisation";
import ViewOrganisation from "./components/Pages/OrganisationManagement/ViewOrganisation";
import EditOrganisation from "./components/Pages/OrganisationManagement/EditOrganisation";
import CreateAdmin from "./components/Pages/User/CreateAdmin/CreateAdmin";
import SMSSubscriptions from "./components/Pages/SMSSubscription/SMSSubscriptions";
import AddSmsSubscription from "./components/Pages/SMSSubscription/AddSmsSubscription";
import UserSubscriptions from "./components/Pages/UserSubscription/UserSubscriptions";
import AddUserSubscription from "./components/Pages/UserSubscription/AddUserSubscription";
import EditUserSubscription from "./components/Pages/UserSubscription/EditUserSubscription";
import EmailSubscriptions from "./components/Pages/EmailSubscription/EmailSubscription";
import AddEmailSubscription from "./components/Pages/EmailSubscription/AddEmailSubscription";
import MimsManagement from "./components/Pages/MimsManagement/MimsManagement";
import Users from "./components/Pages/Users/Users";
import CreateMimsUser from "./components/Pages/MimsManagement/CreateMimsUser";
import UpdateMimsUser from "./components/Pages/MimsManagement/UpdateMimsUser";
import CreateUser from "./components/Pages/Users/CreateUser";
import EditUser from "./components/Pages/Users/EditUser";
import RolesManagement from "./components/Pages/RolesManagement/RolesManagement";
import AddRole from "./components/Pages/SMSSubscription/AddRole";
import Permissions from "./components/Pages/RolesManagement/Permissions";
import EditRole from "./components/Pages/SMSSubscription/EditRole";
import Tests from "./components/Pages/Tests/Tests";
import AddTest from "./components/Pages/Tests/AddTest";
import EditTest from "./components/Pages/Tests/EditTest";
import Immunisation from "./components/Pages/Immunisation/Immunisation";
import Vaccine from "./components/Pages/Immunisation/Vaccine/Vaccine";
import AddVaccine from "./components/Pages/Immunisation/Vaccine/AddVaccine";
import EditVaccine from "./components/Pages/Immunisation/Vaccine/EditVaccine";
import NaturalImmunity from "./components/Pages/Immunisation/NaturalImmunity/NaturalImmunity";
import AddNaturalImmunity from "./components/Pages/Immunisation/NaturalImmunity/AddNaturalImmunity";
import EditNaturalImmunity from "./components/Pages/Immunisation/NaturalImmunity/EditNaturalImmunity";
import MedContra from "./components/Pages/Immunisation/MedContra/MedContra";
import AddMedContra from "./components/Pages/Immunisation/MedContra/AddMedContra";
import EditMedContra from "./components/Pages/Immunisation/MedContra/EditMedContra";
import Disease from "./components/Pages/Immunisation/Disease/Disease";
import AddDisease from "./components/Pages/Immunisation/Disease/AddDisease";
import EditDisease from "./components/Pages/Immunisation/Disease/EditDisease";
import FeeManagement from "./components/Pages/FeeManagment/FeeManagement";
import Fees from "./components/Pages/FeeManagment/Fees";
import MonthlyMBS from "./components/Pages/FeeManagment/MonthlyMBS/MonthlyMBS";
import AddMonthlyFile from "./components/Pages/FeeManagment/MonthlyMBS/AddMonthlyFile";
import EditMonthlyFile from "./components/Pages/FeeManagment/MonthlyMBS/EditMonthlyFile";
import MimsSubscriptions from "./components/Pages/MimsManagement/MimsSubscriptions";
import MimsIntegratedUsers from "./components/Pages/MimsManagement/MimsIntegratedUsers";
import OtherMimsUsers from "./components/Pages/MimsManagement/OtherMimsUsers";
import Jobs from "./components/Pages/Jobs/Jobs";
import ProdaJobs from "./components/Pages/Jobs/ProdaJobs";
import MimsJobs from "./components/Pages/Jobs/MimsJobs";
import MbsJobs from "./components/Pages/Jobs/MbsJobs";
import SubscriptionJobs from "./components/Pages/Jobs/SubscriptionJobs";
import FailedJobs from "./components/Pages/Jobs/FailedJobs";
import EditSmsSubscription from "./components/Pages/SMSSubscription/EditSmsSubscription";
import EditEmailSubscription from "./components/Pages/EmailSubscription/EditEmailSubscription";
import AppointmentType from "./components/Pages/AppointmentType/AppointmentType";
import AddAppointmentType from "./components/Pages/AppointmentType/AddAppointmentType";
import ViewAppointmentType from "./components/Pages/AppointmentType/ViewAppointmentType/ViewAppointmentType";
import EditAppointmentType from "./components/Pages/AppointmentType/EditAppointmentType/EditAppointmentType";
import ProdaExceptions from "./components/Pages/ProdaExceptions/ProdaExceptions";
import PrescriptionTemplates from "./components/Pages/MimsManagement/PrescriptionTemplates";

const App = () => {
  const { pathname } = useLocation();
  const user = storageService.loadUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const intervalRef = useRef(null);
  const [, authDispatch] = useAuthContext();
  const navigate = useNavigate();

  const loggedUser = storageService.loadUser() != null;

  let privilages = storageService.loadPermission();

  if (privilages) {
    var bytes = CryptoJS.AES.decrypt(privilages, "secret key 123");
    privilages = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  if (loggedUser) {
    dispatch(savePrivilages(privilages));
  }

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route
              path="/app"
              element={
                loggedUser ? <Navigate to="/app/dashboard" /> : <Login />
              }
            />

            <Route
              path="/app/login"
              element={
                loggedUser ? (
                  <Navigate to="/app/dashboard" replace={true} />
                ) : (
                  <Login />
                )
              }
            />

            <Route
              path="/app/forgotpassword"
              element={
                loggedUser ? (
                  <Navigate to="/app/dashboard" replace={true} />
                ) : (
                  <ForgotPassword />
                )
              }
            />

            <Route
              exact
              path={"/activate/:userid/:token"}
              element={<ActivationAccount />}
            />

            <Route exact path={"/reset/:userid/:id"} element={<Reset />} />

            <Route element={<ProtectedRoutes auth={loggedUser} />}>
              <Route path="/app" element={<Body />}>
                <Route path="/app/management" element={<Management />} />
                <Route path="/app/dashboard" element={<Dashboard />} />
                <Route
                  path="/app/sites-management"
                  element={<SitesManagement />}
                />
                <Route
                  path="/app/site/view/:id"
                  element={<ViewSiteDetails />}
                />
                <Route path="/app/site/add" element={<AddSite />} />
                <Route path="/app/site/edit/:id" element={<EditSite />} />
                <Route
                  path="/app/organisations-management"
                  element={<OrganisationManagement />}
                />
                <Route
                  path="/app/organisation/add"
                  element={<AddOrganisation />}
                />
                <Route
                  path="/app/organisation/view/:id"
                  element={<ViewOrganisation />}
                />
                <Route
                  path="/app/organisation/edit/:id"
                  element={<EditOrganisation />}
                />
                <Route path="/app/create/admin/:id" element={<CreateAdmin />} />
                <Route
                  path="/app/sms-subscription"
                  element={<SMSSubscriptions />}
                />
                <Route
                  path="/app/add/sms/subscription"
                  element={<AddSmsSubscription />}
                />
                <Route
                  path="/app/edit/sms/subscription/:id"
                  element={<EditSmsSubscription />}
                />
                <Route
                  path="/app/email-subscription"
                  element={<EmailSubscriptions />}
                />
                <Route
                  path="/app/add/email/subscription"
                  element={<AddEmailSubscription />}
                />
                <Route
                  path="/app/edit/email/subscription/:id"
                  element={<EditEmailSubscription />}
                />
                <Route
                  path="/app/user-subscription"
                  element={<UserSubscriptions />}
                />
                <Route
                  path="/app/add/user/subscription"
                  element={<AddUserSubscription />}
                />
                <Route
                  path="/app/edit/user/subscription/:id"
                  element={<EditUserSubscription />}
                />
                <Route
                  path="/app/mims-management"
                  element={<MimsManagement />}
                />
                <Route path="/app/add/mims/user" element={<CreateMimsUser />} />
                <Route
                  path="/app/update/mims-subscription/:id"
                  element={<UpdateMimsUser />}
                />
                <Route path="/app/users" element={<Users />} />
                <Route path="/app/add/super-admin" element={<CreateUser />} />
                <Route path="/app/user/edit/:id" element={<EditUser />} />
                <Route
                  path="/app/roles-management"
                  element={<RolesManagement />}
                />
                <Route path="/app/add/role" element={<AddRole />} />
                <Route path="/app/edit/role/:id" element={<EditRole />} />
                <Route
                  path="/app/role/permissions/:id"
                  element={<Permissions />}
                />
                <Route path="/app/tests" element={<Tests />} />
                <Route path="/app/add/test" element={<AddTest />} />
                <Route path="/app/edit/test/:id" element={<EditTest />} />
                <Route path="/app/immunisation" element={<Immunisation />} />
                <Route path="/app/vaccine" element={<Vaccine />} />
                <Route path="/app/add/vaccine" element={<AddVaccine />} />
                <Route path="/app/edit/vaccine/:id" element={<EditVaccine />} />
                <Route path="/app/disease" element={<Disease />} />
                <Route path="/app/add/disease" element={<AddDisease />} />
                <Route path="/app/edit/disease/:id" element={<EditDisease />} />
                <Route
                  path="/app/natural-immunity"
                  element={<NaturalImmunity />}
                />
                <Route
                  path="/app/add/natural-immunity"
                  element={<AddNaturalImmunity />}
                />
                <Route
                  path="/app/edit/natural-immunity/:id"
                  element={<EditNaturalImmunity />}
                />
                <Route path="/app/med-contra" element={<MedContra />} />
                <Route path="/app/add/med-contra" element={<AddMedContra />} />
                <Route
                  path="/app/edit/med-contra/:id"
                  element={<EditMedContra />}
                />
                <Route path="/app/fee-management" element={<FeeManagement />} />
                <Route path="/app/fees" element={<Fees />} />
                <Route path="/app/monthly/files" element={<MonthlyMBS />} />
                <Route
                  path="/app/add/monthly-file"
                  element={<AddMonthlyFile />}
                />
                <Route
                  path="/app/edit/monthly-file/:id"
                  element={<EditMonthlyFile />}
                />
                <Route
                  path="/app/mims/users"
                  element={<MimsIntegratedUsers />}
                />
                <Route
                  path="/app/mims/other/users"
                  element={<OtherMimsUsers />}
                />
                <Route
                  path="/app/mims/subscriptions"
                  element={<MimsSubscriptions />}
                />
                <Route
                  path="/app/prescription/template"
                  element={<PrescriptionTemplates />}
                />
                <Route path="/app/cron-jobs" element={<Jobs />} />
                <Route path="/app/proda-jobs" element={<ProdaJobs />} />
                <Route path="/app/mims-jobs" element={<MimsJobs />} />
                <Route path="/app/mbs-jobs" element={<MbsJobs />} />
                <Route path="/app/failed-jobs" element={<FailedJobs />} />
                <Route
                  path="/app/appointment-type"
                  element={<AppointmentType />}
                />
                <Route
                  path="/app/appointment-type/add"
                  element={<AddAppointmentType />}
                />
                <Route
                  path="/app/appointment-type/view/:id"
                  element={<ViewAppointmentType />}
                />
                <Route
                  path="/app/appointment-type/edit/:id"
                  element={<EditAppointmentType />}
                />
                <Route
                  path="/app/subscription-jobs"
                  element={<SubscriptionJobs />}
                />
                <Route
                  path="/app/proda-exceptions"
                  element={<ProdaExceptions />}
                />
              </Route>
            </Route>
          </Routes>
        </div>
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
