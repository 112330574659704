import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
} from "@mui/material";
import { Box } from "@mui/system";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import classes from "./RolesManagement.module.css";
import theme from "../../Theme/Theme";
import { ThemeProvider } from "react-bootstrap";
import Button from "../../UI/Button/Button";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import axios from "axios";
import SelectBox from "../../UI/FormGroup/SelectBox/SelectBox";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import backendUrl from "../../utils/backendUrl";
import RadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import storageService from "../../utils/localStorageHelpers";
import Loader from "../../UI/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import useMediaQuery from "@mui/material/useMediaQuery";
import CautionAlert from "../../utils/CautionAlert";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Icon } from "@iconify/react";
import AddFeaturePopup from "./AddFeaturePopup";

const Permissions = (props) => {
  const widthIs14 = useMediaQuery("(max-width:1399px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(null);
  const [noData, setNoData] = useState(false);
  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);
  const [editRow1, setEditRow1] = useState(null);
  const [editRow2, setEditRow2] = useState(null);
  const [editedRows, setEditedRows] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [openAddPermission, setOpenAddPermission] = useState(false);

  useEffect(() => {
    loadPermissions();
  }, []);

  useEffect(() => {
    if (data) {
      const middleIndex = Math.floor(data.length / 2);
      const firstHalf = data.slice(0, middleIndex + 1);
      const secondHalf = data.slice(middleIndex + 1);

      setEditRow1(firstHalf);
      setEditRow2(secondHalf);
    }
  }, [data]);

  const loadPermissions = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/permissions?role_id=${id}`,
      withCredentials: true,
    })
      .then((response) => {
        setLoader(false);
        if (response.data?.data) {
          var dt = response.data?.data;
          setData(dt);
          setNoData(false);
        } else {
          setError("Something went wrong. Please try again");
          setNoData(true);
        }
      })
      .catch((e) => {
        
        setNoData(true);
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const submitValidation = () => {
    if (!editedRows.length) {
      return false;
    } else {
      return true;
    }
  };

  const submitHandler = async () => {
    const toProceed = submitValidation();

    if (!toProceed) {
      setError("You've made no changes");
      setLoader(false);
      return;
    }

    return submitFunction();
  };

  const submitFunction = async () => {
    setLoader(true);
    await axios({
      method: "PATCH",
      url: `${backendUrl}/api/permission/bulk/update`,
      data: { rows: editedRows },
      withCredentials: true,
    })
      .then((res) => {
        setLoader(false);
        // successHandler("User has been successfully added");
        loadPermissions();
        setEditedRows([])
        setSuccess("Permission has been successfully updated");
      })
      .catch((error) => {
        setLoader(false);
        

        setError("Something went wrong. Please try again");
      });
  };

  const retry = () => {
    setError(null);
    setTimeout(() => {
      loadPermissions();
    }, 100);
  };

  const editHandler = (item) => {
    let exist = editedRows.find((a) => a.name === item.name);
    if (exist) {
      let i = editedRows.findIndex((obj) => obj.name === exist.name);
      setEditedRows((prev) => {
        prev.splice(i, 1);
        return [...prev];
      });
    } else {
      setEditedRows((prev) => {
        prev.push(item);
        return [...prev];
      });
    }
  };

  const oneChangeHandler = (item, operation) => {
    let opIndex = null;
    if (operation === "CREATE") {
      opIndex = 0;
    } else if (operation === "READ") {
      opIndex = 1;
    } else if (operation === "UPDATE") {
      opIndex = 2;
    } else {
      opIndex = 3;
    }
    let dt = editedRows.find((a) => item.name === a.name);
    dt = { ...dt };
    let i = editedRows.findIndex((obj) => obj.name === dt.name);
    let a = dt.permission;
    a = a.split("");
    let v = a[opIndex];
    a[opIndex] = v === "1" ? "0" : "1";
    dt.permission = a.join("");
    setEditedRows((prev) => {
      prev[i] = { ...dt };
      return [...prev];
    });
  };

  const twoChangeHandler = (item, operation) => {
    let opIndex = null;
    if (operation === "CREATE") {
      opIndex = 0;
    } else if (operation === "READ") {
      opIndex = 1;
    } else if (operation === "UPDATE") {
      opIndex = 2;
    } else {
      opIndex = 3;
    }

    let dt = editedRows.find((a) => a.name === item.name);
    dt = { ...dt };
    let i = editedRows.findIndex((obj) => obj.name === dt.name);
    let a = dt.permission;
    a = a.split("");
    let v = a[opIndex];
    a[opIndex] = v === "1" ? "0" : "1";
    dt.permission = a.join("");
    setEditedRows((prev) => {
      prev[i] = { ...dt };
      return [...prev];
    });
  };

  const deleteHandler = async () => {
    let item = deleteItem;
    setDeleteItem(null);
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/permission/${item.id}`,
    })
      .then(() => {
        setLoader(false);
        setSuccess(`Permission has been successfully deleted`);
        loadPermissions();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const savePermissionHandler = async (feature) => {
    setLoader(true);
    setOpenAddPermission(false);
    let body = {
      feature,
      role_id: id,
    };

    await axios
      .post(`${backendUrl}/api/add/single/permission`, body, {
        withCredentials: true,
      })
      .then((res) => {
        setLoader(false);
        loadPermissions();
        setSuccess("Permission has been added successfully");
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/roles-management",
      iconName: "eos-icons:role-binding",
      name: "Roles Management",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Breadcrumb options={breadCrumbData} />
      <Loader open={loader} />
      {openAddPermission && (
        <AddFeaturePopup
          open={openAddPermission}
          closeHandler={() => setOpenAddPermission(false)}
          saveFeatureHandler={savePermissionHandler}
        />
      )}
      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          oneButton={true}
          onCloseActionB={() => {
            setSuccess(null);
          }}
          okText="Ok"
        />
      ) : null}

      {error ? (
        <>
          <CautionAlert
            severity="error"
            open={error}
            content={error}
            oneButton={true}
            onCloseActionB={() => {
              setError(null);
            }}
            okText="Ok"
          />
        </>
      ) : null}

      {deleteItem ? (
        <>
          <CautionAlert
            severity="error"
            open={deleteItem}
            content={"Are you sure you want to delete this permission?"}
            onCloseActionB={deleteHandler}
            onCloseActionA={() => {
              setDeleteItem(null);
            }}
            okText="Yes"
            cancelText="No"
          />
        </>
      ) : null}

      <PageHeader
        left={`Permissions - [ ${data?.[0]?.role?.name} ]`}
        right={
          <Button
            // disabled={openAddUser === true ? true : false}
            onClickAction={() => setOpenAddPermission(true)}
            text="Add Permission"
            startIcon={
              <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
            }
          ></Button>
        }
      />
      <form>
        {noData ? (
          ""
        ) : (
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "800px !important" }}
                className={classes.Max}
              >
                <TableContainer>
                  <Table sx={{ border: "1px solid #e5e5e5" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Feature</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Create</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Read</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Update</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Delete</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Edit</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editRow1?.map((row, index) => {
                        let isEditable = editedRows.find(
                          (a) => row.name === a.name
                        );
                        let value = isEditable ? isEditable : row;
                        return (
                          <TableRow key={row.id}>
                            <TableCell className={classes.TableCell}>
                              {row.name}
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[0] === "1"}
                                value={value?.permission[0] === "1"}
                                onChange={() => oneChangeHandler(row, "CREATE")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[1] === "1"}
                                value={value?.permission[1] === "1"}
                                onChange={() => twoChangeHandler(row, "READ")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[2] === "1"}
                                value={value?.permission[2] === "1"}
                                onChange={() => oneChangeHandler(row, "UPDATE")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[3] === "1"}
                                value={value?.permission[3] === "1"}
                                onChange={() => oneChangeHandler(row, "DELETE")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Grid container xs={12}>
                                <Grid item xs={6}>
                                  <IconButton
                                    aria-label="add an alarm"
                                    onClick={() => editHandler(row)}
                                  >
                                    {" "}
                                    <Icon
                                      className={classes.Icon}
                                      icon="tabler:edit"
                                    />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                  <IconButton
                                    aria-label="add an alarm"
                                    onClick={() => setDeleteItem(row)}
                                  >
                                    {" "}
                                    <Icon
                                      className={classes.DeleteIcon}
                                      icon="fluent:delete-12-regular"
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "800px !important" }}
                className={classes.Max}
              >
                <TableContainer>
                  <Table sx={{ border: "1px solid #e5e5e5" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Feature</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Create</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Read</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Update</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Delete</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Edit</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editRow2?.map((row, index) => {
                        let isEditable = editedRows.find(
                          (a) => row.name === a.name
                        );
                        let value = isEditable ? isEditable : row;
                        return (
                          <TableRow key={row.id}>
                            <TableCell className={classes.TableCell}>
                              {row.name}
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[0] === "1"}
                                value={value?.permission[0] === "1"}
                                onChange={() => twoChangeHandler(row, "CREATE")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[1] === "1"}
                                value={value?.permission[1] === "1"}
                                onChange={() => twoChangeHandler(row, "READ")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[2] === "1"}
                                value={value?.permission[2] === "1"}
                                onChange={() => twoChangeHandler(row, "UPDATE")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Checkbox
                                disabled={!isEditable}
                                checked={value?.permission[3] === "1"}
                                value={value?.permission[3] === "1"}
                                onChange={() => twoChangeHandler(row, "DELETE")}
                                style={{
                                  color: !isEditable ? null : "#615586",
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <Grid container xs={12}>
                                <Grid item xs={6}>
                                  <IconButton
                                    aria-label="add an alarm"
                                    onClick={() => editHandler(row)}
                                  >
                                    {" "}
                                    <Icon
                                      className={classes.Icon}
                                      icon="tabler:edit"
                                    />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                  <IconButton
                                    aria-label="add an alarm"
                                    onClick={() => setDeleteItem(row)}
                                  >
                                    {" "}
                                    <Icon
                                      className={classes.DeleteIcon}
                                      icon="fluent:delete-12-regular"
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        )}
        <PageFooter
          right={
            <Fragment>
              {noData ? (
                ""
              ) : (
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button text="Save" onMouseDownAction={submitHandler} />
                </Box>
              )}

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={(e) => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
};

export default Permissions;
