import timestampHandler from "../utils/timstampHandler";

const siteTableColumns = [
  {
    text: "Site Name",
    dataField: "site_name",
  },
  {
    text: "Minor ID",
    dataField: "minor_id",
  },
  {
    text: "Business Name",
    dataField: "business_name",
  },
  {
    text: "ABN Number",
    dataField: "abn_number",
  },
  {
    text: "HPIO Number",
    dataField: "hpio_number",
  },
  {
    text: "Phone Number",
    dataField: "work_phone",
  },
  {
    text: "Email",
    dataField: "work_email",
  },
  {
    text: "Active",
    dataField: "active",
    formatter:(value) =>{
      return value ? "Yes" : "No"
    }
  },
  {
    dataField: "action",
    text: "Action",
  },
];

export default {
  siteTableColumns,
};
